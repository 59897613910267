export const routes = {
  homepage: "/",
  login: "/login",
  home: "/home",
  maiar: "/maiar",
  word: '/word',
  show: '/show',
  history: '/history',
  collection: '/collection',
  collectionDetails: '/collectionDetails',
  profile: '/profile',
  notification: '/notification',
  famers: '/famers',
  topic: '/topic',
  number: '/number',
  leaders: '/leaders',
  topic2: '/topic2',
  lesson: '/lesson',
  lessonDetails: '/lessonDetails',
  steal: '/steal',
  henyo: '/henyo',
  henyo2: '/henyo2',
  henyo3: '/henyo3',
  slot: '/slot',
  lotto: '/lotto',
  funtyperunner: "/funtyperunner/:id",
  winners: '/winners',
};

export const ids = {
  MaxWinsTopic: 50,
  MaxWinsWord: 5,
  MaxWinsNumber: 5,

  FunTypeFamilyFamer: 1,
  FunTypeFamilyTourney: 2,
  FunTypeFamilyHighlight: 3,
  FunTypeFamilyRondleWords: 7,
  FunTypeFamilyRondleTopics: 8,
  FunTypeFamilyRondleNumbers: 9,

  //transactionType
  txnTypeJackpot: 3,
  txnTypeBattleFee: 5,
  txnTypeBattleWin: 6,
  txnTypeBattleDraw: 7,
  txnTypeBattleCancel: 8,
  txnTypeTourneyFee: 9,
  txtTypeWelcomeTreat: 17,
  txnTypeBoughtCharm: 20,
  txnTypeBrandFamer: 21,
  txnTypeBrandHOF: 22,
  txnTypeBrandJackpot: 23,
  txnTypeBrandNineBoxes: 24,
  txnTypeRondleWordWin: 25,
  txnTypeRondleTopicWin: 26,
  txnTypeRondleNumberWin: 27,
  txnTypeRondleWordFee: 28,
  txnTypeRondleTopicFee: 29,
  txnTypeRondleNumberFee: 30,

  notificationTypeConfetti: 40,
  notificationTypeSteal: 41,

  benggaStoreId: 1,

  gameTypeFunType: 0,
  gameTypeWordle: 1,
  gameTypeFillInTheBlanks: 2,
  gameType2048: 3,
  gameTypeMultipleChoice: 4,
  gameTypeWheel: 5,
  gameTypeSlotMachine: 6,
  gameTypeLotto: 7,
  gameTypeCubeRun: 8,
  gameTypePinoyHenyo: 9, //this that multiple choice
  gameTypePinoyHenyo2: 10, //this that know with keyboard
  gameTypePinoyHenyo3: 11, //clue then keyboard

  classA: 4,
  classB: 3,
  classC: 2,
  classD: 1,

  rondleTypeEarlyRelease: 0,
  rondleTypePharma: 1,
  rondleTypeDobleKahol: 2,
}